import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      "Warning: This site is purely fictitious, no money transaction is possible.":
        "Warning: This site is purely fictitious, no money transaction is possible.",
      "Please understand, however, that playing on any other platform may be addictive.":
        "Please understand, however, that playing on any other platform may be addictive.",
      "If you think you have a problem, please consult the player info service site: ":
        "If you think you have a problem, please consult the player info service site: ",
      Register: "Register",
      Login: "Login",
      "Search a match": "Search a match",
      All: "All",
      "Top competitions": "Top competitions",
      Games: "Jeux",
      Draw: "Draw",
      "Get free coins": "Get free coins",
      "You can claim free coins every days !":
        "You can claim free coins every days !",
      Round: "Round",
      Match: "Match",
      Dashboard: "Dashboard",
      Logout: "Logout",
      'No account yet?' : 'No account yet?',
      'Create one for free ' : 'Create one for free ',
      here: 'here',
      Submit: 'Submit',
      Semaine: 'Week',
    },
  },
  fr: {
    translation: {
      "Warning: This site is purely fictitious, no money transaction is possible.":
        "Attention : Ce site est purement fictif, aucune transaction d'argent n'y est possible",
      "Please understand, however, that playing on any other platform may be addictive.":
        "Veuillez toutefois comprendre que jouer sur une quelconque autre platforme peut comporter des risques d'addiction.",
      "If you think you have a problem, please consult the player info service site: ":
        "Si vous pensez avoir un problème, veuillez consulter le site de joueurs infos service : ",
      Register: "S'inscrire",
      Login: "Connexion",
      "Search a match": "Rechercher un match",
      All: "Tous",
      "Top competitions": "Top des competitions",
      Games: "Jeux",
      Draw: "Nul",
      "Get free coins": "Obtenez des crédits gratuits",
      "You can claim free coins every days !":
        "Récupérez des crédits gratuits tous les jours !",
      Round: "Manche",
      Match: "Matche",
      Dashboard: "Tableau de bord",
      Logout: "Déconnexion",
      'No account yet?' : 'Vous n\'avez pas encore de compte ?',
      'Create one for free ' : 'Créez-en un gratuitement ',
      here: 'ici',
      Submit: 'Envoyer',
      Username: 'Nom d\'utilisateur',
      Name: 'Prénom',
      Lastname: 'Nom',
      Password: 'Mot de passe',
      'Confirm Password': 'Confirmer le mot de passe',
      Man: 'Monsieur',
      Woman: 'Madame',
      Gender: 'Civilité',
      Redeem: 'Obtenir',
      User: 'Utilisateur',
      Coins: 'Crédits',
      Bets: 'Paris',
      Team: 'Equipe',
      Ammount: 'Montant',
      'Bet\'s Board': 'Tableau des paris',
      "Account not found": "Compte introuvable",
        "No account found with this email": "Aucun compte trouvé avec cet email",
        "Incorrect password": "Mot de passe incorrect",
        "Please check your email and password": "Veuillez vérifier votre email et votre mot de passe",
        "Account not validated": "Compte non validé",
        "Please check your emails and click on the link to verify your account": "Veuillez vérifier vos emails et cliquer sur le lien pour valider votre compte",

    },
  },
  es: {
    translation: {
        "Warning: This site is purely fictitious, no money transaction is possible.":
        "Advertencia: Este sitio es puramente ficticio, no es posible ninguna transacción de dinero.",
        "Please understand, however, that playing on any other platform may be addictive.":
        "Sin embargo, comprenda que jugar en cualquier otra plataforma puede ser adictivo.",
        "If you think you have a problem, please consult the player info service site: ":
        "Si cree que tiene un problema, consulte el sitio de servicio de información para jugadores:",
        Register: "Registrarse",
        Login: "Iniciar sesión",
        "Search a match": "Buscar un partido",
        All: "Todos",
        "Top competitions": "Principales competiciones",
        Games: "Juegos",
        Draw: "Empate",
        "Get free coins": "Obtenga monedas gratis",
        "You can claim free coins every days !":
        "¡Puedes reclamar monedas gratis todos los días!",
        Round: "Ronda",
        Match: "Partido",
        Dashboard: "Tablero",
        Logout: "Cerrar sesión",
        'No account yet?' : '¿Todavía no tienes una cuenta?',
        'Create one for free ' : 'Crea uno gratis ',
        here: 'aquí',
        Submit: 'Enviar',
        Username: 'Nombre de usuario',
        Name: 'Nombre',
        Lastname: 'Apellido',
        Password: 'Contraseña',
        'Confirm Password': 'Confirmar contraseña',
        Gender: 'Género',
        Man: 'Hombre',
        Woman: 'Mujer',
        Redeem: 'Canjear',
        User: 'Usuario',
        Coins: 'Monedas',
        Bets: 'Apuestas',
        Semaine: 'Semana',
        Team: 'Equipo',
        Ammount: 'Cantidad',
        'Bet\'s Board': 'Tablero de apuestas',
        "Account not found": "Cuenta no encontrada",
        "No account found with this email": "No se encontró ninguna cuenta con este correo electrónico",
        "Incorrect password": "Contraseña incorrecta",
        "Please check your email and password": "Por favor, compruebe su correo electrónico y su contraseña",
        "Account not validated": "Cuenta no validada",
        "Please check your emails and click on the link to verify your account": "Por favor, compruebe sus correos electrónicos y haga clic en el enlace para verificar su cuenta",
    },
  },
  de: {
        translation: {
            "Warning: This site is purely fictitious, no money transaction is possible.":
            "Warnung: Diese Seite ist rein fiktiv, es ist kein Geldtransaktion möglich.",
            "Please understand, however, that playing on any other platform may be addictive.":
            "Bitte verstehen Sie jedoch, dass das Spielen auf einer anderen Plattform süchtig machen kann.",
            "If you think you have a problem, please consult the player info service site: ":
            "Wenn Sie der Meinung sind, dass Sie ein Problem haben, konsultieren Sie bitte die Website des Spieler-Info-Dienstes:",
            Register: "Registrieren",
            Login: "Anmeldung",
            "Search a match": "Suche ein Spiel",
            All: "Alle",
            "Top competitions": "Top-Wettbewerbe",
            Games: "Spiele",
            Draw: "Unentschieden",
            "Get free coins": "Holen Sie sich kostenlose Münzen",
            "You can claim free coins every days !":
            "Sie können jeden Tag kostenlose Münzen beanspruchen!",
            Round: "Runde",
            Match: "Spiel",
            Dashboard: "Instrumententafel",
            Logout: "Ausloggen",
            'No account yet?' : 'Noch kein Konto?',
            'Create one for free ' : 'Erstellen Sie kostenlos ein Konto ',
            here: 'hier',
            Submit: 'einreichen',
            Gender: 'Geschlecht',
            Username: 'Nutzername',
            Name: 'Name',
            Lastname: 'Nachname',
            Password: 'Passwort',
            'Confirm Password': 'Bestätige das Passwort',
            Man: 'Mann',
            Woman: 'Frau',
            Redeem: 'Einlösen',
            User: 'Benutzer',
            Coins: 'Münzen',
            Bets: 'Wetten',
            Semaine: "Woche",
            Team: 'Mannschaft',
            Ammount: 'Menge',
            'Bet\'s Board': 'Wettbrett',
            "Account not found": "Konto nicht gefunden",
            "No account found with this email": "Kein Konto mit dieser E-Mail gefunden",
            "Incorrect password": "Falsches Passwort",
            "Please check your email and password": "Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort",
            "Account not validated": "Konto nicht validiert",
            "Please check your emails and click on the link to verify your account": "Bitte überprüfen Sie Ihre E-Mails und klicken Sie auf den Link, um Ihr Konto zu verifizieren",
        }
    },
  it: {
        translation: {
            "Warning: This site is purely fictitious, no money transaction is possible.":
            "Avvertenza: questo sito è puramente fittizio, non è possibile alcuna transazione di denaro.",
            "Please understand, however, that playing on any other platform may be addictive.":
            "Tuttavia, comprendi che giocare su qualsiasi altra piattaforma può essere dipendente.",
            "If you think you have a problem, please consult the player info service site: ":
            "Se pensi di avere un problema, consulta il sito del servizio informazioni per i giocatori:",
            Register: "Registrati",
            Login: "Accesso",
            "Search a match": "Cerca una partita",
            All: "Tutti",
            "Top competitions": "Top competizioni",
            Games: "Giochi",
            Draw: "Disegnare",
            "Get free coins": "Ottieni monete gratis",
            "You can claim free coins every days !":
            "Puoi richiedere monete gratuite tutti i giorni!",
            Round: "Giro",
            Match: "Partita",
            Dashboard: "Cruscotto",
            Logout: "Disconnettersi",
            Gender: 'Genere',
            Username: 'Nome utente',
            Name: 'Nome',
            Lastname: 'Cognome',
            Password: 'Parola d\'ordine',
            'Confirm Password': 'Conferma password',
            Man: 'Uomo',
            Woman: 'Donna',
            Redeem: 'Riscattare',
            User: 'Utente',
            Coins: 'Monete',
            Bets: 'Scommesse',
            Semaine: 'Settimana',
            Team: 'Squadra',
            Ammount: 'Ammontare',
            'Bet\'s Board': 'Scheda scommesse',
            "Account not found": "Account non trovato",
            "No account found with this email": "Nessun account trovato con questa email",
            "Incorrect password": "Password errata",
            "Please check your email and password": "Controlla la tua email e la tua password",
            "Account not validated": "Account non convalidato",
            "Please check your emails and click on the link to verify your account": "Controlla le tue email e fai clic sul link per verificare il tuo account",
        }
    },
};

export default i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});
